
















































































































































































































































































































































































































































































































































































.el-textarea__inner {
  min-height: 260px !important;
}
.el-tooltip__popper {
  max-width: 45% !important;
}
